import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

import {
  SupportedLanguageGroupIdentifier,
  SupportedRegionIdentifier,
  supportedRegions,
} from '@syconium/weeping-figs'

import { useFixturesContext } from '../../brunswick/containers/fixtures'
import { LocalizationContainer } from '../../brunswick/containers/localization/LocalizationContainer'

type HreflangLink = {
  rel: 'alternate'
  hreflang: `${SupportedLanguageGroupIdentifier}-${SupportedRegionIdentifier}` | 'x-default'
  href: string
}

export function useLocalizedMeta() {
  const {
    locale: selectedLocale,
    region: selectedRegion,
    languageGroup: selectedLanguage,
    requestIncludedLocalizationPathParam,
  } = LocalizationContainer.useContainer()
  const { asPath } = useRouter()
  const rel = 'alternate'

  const {
    globals: {
      canonicalBaseUrl: { home },
    },
  } = useFixturesContext()
  const homeNoTrailingSlash = home.replace(/\/$/, '') // remove trailing slash

  const getAlternateLinks = useCallback(
    (canonicalUrl?: string) => {
      let path = asPath
      if (canonicalUrl) path = canonicalUrl.split(homeNoTrailingSlash)[1] ?? ''
      const pathNoTrailingSlash = path.replace(/\/$/, '')
      const links: HreflangLink[] = [
        {
          rel: rel,
          hreflang: 'x-default',
          href: `${homeNoTrailingSlash}${path}`,
        },
      ]

      Object.entries(supportedRegions).forEach(([_regionCode, region]) => {
        if (region.isEnabled || region.id === selectedRegion.id) {
          Object.entries(region.languages).forEach(([languageGroupAsString, regionalLanguage]) => {
            if (
              regionalLanguage.isEnabled ||
              (languageGroupAsString === selectedLanguage && region.id === selectedRegion.id)
            ) {
              const languageGroup =
                languageGroupAsString as unknown as keyof typeof region.languages
              const languageRegionCombo: `${SupportedLanguageGroupIdentifier}-${SupportedRegionIdentifier}` = `${languageGroup}-${region.id}`
              const href = `${home}${languageRegionCombo}${pathNoTrailingSlash}` // don't use slash for int'l
              links.push({
                rel: rel,
                hreflang: languageRegionCombo,
                href: href,
              })
            }
          })
        }
      })

      return links
    },
    [asPath, home, homeNoTrailingSlash, selectedLanguage, selectedRegion.id]
  )

  const getLocalizedCanonicalUrl = useCallback(
    (canonicalUrl: string | undefined) => {
      if (!canonicalUrl || !requestIncludedLocalizationPathParam) return canonicalUrl
      const homeNoTrailingSlash = home.replace(/\/$/, '') // remove trailing slash
      const path = (canonicalUrl.split(homeNoTrailingSlash)[1] ?? asPath).replace(/\/$/, '') // remove trailing slash
      return `${home}${selectedLanguage}-${selectedRegion.id}${path}`
    },
    [asPath, home, selectedLanguage, selectedRegion.id, requestIncludedLocalizationPathParam]
  )

  const formattedLocale = useMemo(() => {
    return selectedLocale.replace('-', '_')
  }, [selectedLocale])

  return {
    getAlternateLinks,
    getLocalizedCanonicalUrl,
    formattedLocale,
  }
}
